<template>
  <section class="about">
    <div class="container2">
      <div class="inner">
        <div class="textbox1">
          <span>言葉にはその由来があり、</span>
          <span>その場所には歴史があります。</span>
          <span>「言の場」はその由来と場所を紐づけて、</span>
          <span>日本全国の地名の由来を</span>
          <span>カルタで見つけることができます。</span>
        </div>
        <router-link to="/karuta" class="titlebox2" >
          <h1><span>『</span>言の場<span>』</span></h1>
          <p class="logo">言場</p>
          <p class="blink">Click here !!</p>
        </router-link>
      </div>
    </div>
  </section>
</template>
