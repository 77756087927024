import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/about.vue'

const routes = [
  {
    path: '/',
    name: 'About',
    component: About
  },
  {
    path: '/karuta',
    name: 'Karuta',
    component: () => import(/* webpackChunkName: "karuta" */ '../views/karuta.vue')
  },
  {
    path: '/karuta/:tag',
    name: 'Karuta_tag',
    component: () => import(/* webpackChunkName: "karuta" */ '../views/karuta.vue'),
    props: true
  },
  {
    path: '/karuta/search/:q',
    name: 'Karuta_q',
    component: () => import(/* webpackChunkName: "karuta" */ '../views/karuta.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
